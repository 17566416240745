<template>
  <div class="commonHead">
    <div class="title-logo">华资数字人管理后台</div>
    <div class="user">
      <img src="../images/back/avator_header.png" alt="头像" />
      <el-dropdown>
        <span class="el-dropdown-link">
          管理员<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="LogOut()">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    LogOut() {
      this.removeCacheData('token');
      this.$router.replace("/");
    },
  },
  components: {},
};
</script>

<style lang="less" scoped>
.el-dropdown-link {
  cursor: pointer;
  font-size: 16px;
  color: white;
}
.commonHead {
  .user {
    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }
  .title-logo {
    font-size: 36px;
    font-weight: bold;
  }
  color: white;
  background-color: #1a2d97;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
