<template>
  <div class="home">
    <commonHead></commonHead>
    <router-view></router-view>
  </div>
</template>

<script>
import commonHead from "../components/commonHead.vue";
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  components: { commonHead },
};
</script>

<style lang="less" scoped>
.home {
  height: 100vh;
  width: 100vw;
  background: #040e44;
  overflow: hidden;
}
</style>
